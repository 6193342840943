import { SupProfile } from "auth/atoms";
import { Profile } from "./types";
import { supxios } from "api";

export const FETCH_MY_BOOKINGS_QUERY = "FETCH_MY_BOOKINGS";

export const FETCH_PROFILE_QUERY = "FETCH_PROFILE";
export const fetchProfile = async (id: string) => {
  const resp = await supxios.get(`/profiles/${id}`);

  return resp.data as SupProfile;
};

export const updateProfile = async (id: string, profile: Partial<Profile>) => {
  const resp = await supxios.put(`/profiles/${id}`, profile);

  return resp.data as Profile;
};

export const FETCH_BOOKING_QUERY = "FETCH_BOOKING_QUERY";
export const fetchBooking = async (id: string) => {
  return (await supxios.get(`/bookings/${id}`)).data;
};

export const openLocker = async ({
  lockerId,
  bookingId,
}: {
  lockerId: string;
  bookingId: string;
}) => {
  return (await supxios.post(`/bookings/${bookingId}/locker/${lockerId}`)).data;
};

export const checkInBooking = async ({ bookingId }: { bookingId: string }) => {
  return (await supxios.post(`/bookings/${bookingId}/check-in`)).data;
};
